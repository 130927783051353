import { defineRule } from 'vee-validate'
import { required, email, one_of as oneOf, min, regex, is } from '@vee-validate/rules'

// we can add more or all rules later
defineRule('required', required)
defineRule('email', email)
defineRule('oneOf', oneOf)
defineRule('min', min)
defineRule('regex', regex)
defineRule('is', is)
